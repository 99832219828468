/* eslint-disable @typescript-eslint/no-unused-vars */
import { XMarkIcon } from '@heroicons/react/24/solid';
import { IconButton } from '@jameswilliamknight/starbridge-ui';
import React, { useMemo } from 'react';
import {
    EdgeProps as BaseEdgeProps,
    BaseEdge,
    EdgeProps,
    getStraightPath,
    getSmoothStepPath,
    getSimpleBezierPath,
    getBezierPath,
    Position,
    MarkerType,
    EdgeLabelRenderer,
} from 'reactflow';
import useNodes from './useNodes';
import { selectedEdgesAtom } from './selectedEdgesAtom';
import { useAtomValue } from 'jotai';
import { cn } from '../../tools/cn';

const CustomEdge = ({
    id,
    data,
    sourceX,
    sourceY,
    targetX,
    targetY,
    source,
    target,
    ...props
}: EdgeProps) => {
    const { isHorizontal } = data;
    const { unlinkNodes } = useNodes();
    const selectedEdges = useAtomValue(selectedEdgesAtom);

    const isSelected = useMemo(() => {
        return !selectedEdges.includes(id);
    }, [selectedEdges, id]);
    const [edgePath, labelX, labelY] = getBezierPath({
        sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
        targetPosition: isHorizontal ? Position.Left : Position.Top,
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const halfIconSize = 10; // Half of the IconButton size (20 / 2)

    // Styling edges: tl;dr you need to use their css properties.
    // https://github.com/xyflow/xyflow/discussions/3081
    // https://reactflow.dev/learn/customization/theming#overriding-built-in-classes
    return (
        <>
            <BaseEdge
                style={{
                    stroke: '#3D7D86',
                    zIndex: !isSelected ? 50 : 0,
                    position: 'absolute',
                }}
                interactionWidth={5}
                markerStart={MarkerType.ArrowClosed}
                markerEnd={MarkerType.ArrowClosed}
                path={edgePath}
                {...props}
            />
            {/* -translate-x-1/2 */}
            {/* -translate-y-1/2 */}
            <EdgeLabelRenderer>
                <div
                    style={{
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        // pointerEvents: 'all', // trying pointer-events-auto
                    }}
                    className={cn('nodrag nopan absolute pointer-events-auto', {
                        'z-50': !isSelected,
                        'z-auto': isSelected,
                    })}>
                    <IconButton
                        className="relative z-50 drop-shadow-md"
                        variant={isSelected ? 'tertiary' : 'secondary'}
                        cloaked={isSelected}
                        density="compact"
                        onClick={() =>
                            unlinkNodes({
                                sourceNodeId: source,
                                targetNodeId: target,
                            })
                        }>
                        <XMarkIcon></XMarkIcon>
                    </IconButton>
                </div>
            </EdgeLabelRenderer>
        </>
    );
};

export default CustomEdge;
