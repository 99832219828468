import clsx from 'clsx';
import React, {
    useLayoutEffect,
    useRef,
    useState,
    useEffect,
    useMemo,
} from 'react';
import { cn } from '../../tools/cn';
import { nodeStateVariants } from './nodeStateVariants';
import { NodeState } from './nodeState';
import useNodes from './useNodes';

export interface NodeOverlayProps {
    nodeId: string;
    children: React.ReactNode | React.ReactNode[];
    isMouseOverParent: boolean;
    className?: string;
    position: 'top' | 'bottom';
    disabled?: boolean;
}

const NodeOverlay = ({
    nodeId,
    children,
    isMouseOverParent,
    position,
    className = '',
    disabled = false,
}: NodeOverlayProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [overlayHeight, setOverlayHeight] = useState(0);
    const [isHover, setIsHover] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [hideTimeout, setHideTimeout] = useState<NodeJS.Timeout | null>(null);
    const { findNode } = useNodes();

    useLayoutEffect(() => {
        if (ref.current) {
            const { offsetHeight } = ref.current;
            setOverlayHeight(() => offsetHeight);
        }
    }, []);

    useEffect(() => {
        setIsVisible(isMouseOverParent || isHover);
    }, [isHover, isMouseOverParent]);

    const nodeState = useMemo<NodeState | undefined>(
        () => findNode(nodeId)?.state,
        [findNode, nodeId],
    );

    const handleMouseEnter = () => {
        if (isMouseOverParent || isHover) {
            setIsHover(true);
            if (hideTimeout) {
                clearTimeout(hideTimeout);
                setHideTimeout(null);
            }
        }
    };

    const handleMouseLeave = () => {
        const timeout = setTimeout(() => {
            setIsHover(false);
        }, 1000);
        setHideTimeout(timeout);
    };

    const nodeStateClassNames = nodeStateVariants({
        state: nodeState,
    });

    return (
        <div
            className={cn(
                'absolute inset-0 transition-opacity transform-gpu pointer-events-none',
                {
                    'opacity-0': !isVisible || disabled,
                    'opacity-50': !isHover && isVisible && !disabled,
                    'opacity-90': isHover && isVisible && !disabled,
                    'duration-300': isHover,
                    'duration-500': !isHover,
                },
            )}>
            <div
                ref={ref}
                onMouseOver={handleMouseEnter}
                // onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={cn(
                    {
                        'border-2 border-b-0': position === 'top',
                        'border-2 border-t-0': position === 'bottom',
                    },
                    'absolute z-20 left-1/2 transform -translate-x-1/2 flex items-center pointer-events-auto',
                    'dark:bg-neutral-800',
                    {
                        'rounded-t-xl': position === 'top',
                        'rounded-b-xl': position === 'bottom',
                    },
                    'shadow-xl',
                    nodeStateClassNames,
                )}
                style={
                    position === 'top'
                        ? { top: `-${overlayHeight}px` }
                        : { bottom: `-${overlayHeight}px` }
                }>
                <div
                    className={cn(
                        'items-center space-x-10 p-1.5 px-3 flex',
                        className,
                    )}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default NodeOverlay;
