import { Text, TextType } from '@jameswilliamknight/starbridge-ui';
import { ResourceCreate } from './ResourceCreate';

export const ResourceCreateBlock = () => {
    return (
        <div className="w-auto">
            <Text
                textType={TextType.LargeHeading}
                className="">
                Resource
            </Text>
            <ResourceCreateDescription />
            <ResourceCreate />
        </div>
    );
};

const ResourceCreateDescription = () => {
    return (
        <div className="flex flex-col p-5 space-y-2">
            <Text
                textType={TextType.Small}
                className="text-md">
                A resource is something that can be itemised. A movie, a video
                game, etc...
            </Text>
        </div>
    );
};
