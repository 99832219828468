import {
    IconButton,
    Text,
    TextInput,
    TextStyle,
    TextType,
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@jameswilliamknight/starbridge-ui';
import { useRef } from 'react';

import InboxItem from '../components/InboxItem';
import useInbox, { InboxItemResponse } from '../data/useInbox';
import { PlusIcon } from '@heroicons/react/24/solid';

const InboxPage = () => {
    const inboxRef = useRef<HTMLInputElement>(null);
    const { items, createItem: create, isError: error, isLoading } = useInbox();

    const getInputText = () => inboxRef?.current?.value ?? 'nothing';

    if (isLoading) {
        return <span>Loading...</span>;
    }

    if (error) {
        console.log(error);
        return <span>Error, see console</span>;
    }

    return (
        <TooltipProvider>
            <div className="flex flex-col items-center space-y-6">
                <Text
                    textType={TextType.LargeHeading}
                    className="p-10">{`Inbox`}</Text>
                <Text
                    textStyle={TextStyle.Primary}
                    textType={TextType.MediumHeading}
                    className="">{`Create`}</Text>
                <div className="flex space-x-4 pb-10 items-center">
                    <TextInput
                        ref={inboxRef}
                        placeholder="Add a new item here"
                    />

                    <Tooltip>
                        <TooltipTrigger>
                            <IconButton
                                variant="tertiary"
                                density="compact"
                                icon={<PlusIcon />}
                                onClick={() =>
                                    create(getInputText())
                                }></IconButton>
                        </TooltipTrigger>
                        <TooltipContent
                            variant="primary"
                            density="compact"
                            side="right"
                            tooltipContent={`Click to Create`}
                        />
                    </Tooltip>
                </div>
                <Text
                    textStyle={TextStyle.Primary}
                    textType={TextType.MediumHeading}>{`Items`}</Text>
                <div className="mt-10 flex flex-col space-y-6">
                    <ul className="flex flex-col space-y-3 w-72">
                        {items?.items?.map((inboxItem: InboxItemResponse) => (
                            <InboxItem
                                key={inboxItem.id}
                                {...{
                                    item: { ...inboxItem },
                                }}></InboxItem>
                        ))}
                    </ul>
                </div>
            </div>
        </TooltipProvider>
    );
};

export default InboxPage;
