import { Button, TextInput } from '@jameswilliamknight/starbridge-ui';

const AspectCreate = () => {
    return (
        <div className="mx-8 w-auto rounded-lg bg-neutral-700/50 p-4 pb-8 pr-10">
            <div className="mb-4 text-3xl">Aspect</div>
            <div className="text-md">
                An Aspect is a component of a calculation. It wraps formulae.
            </div>
            <div className="text-md">For instance:</div>
            <div className="text-md ml-4">
                "Aesthetic" is an aspect when calculating the priority of a UI
                mockup. It wraps a 1.5x multiplier.
            </div>
            <div className="mb-4 mt-8 text-xl">Current aspects:</div>
            <div className="mb-4 mt-8 text-xl">Create an aspect:</div>
            <div className="space-y-4 pl-5">
                <div className="flex items-center space-x-4">
                    <div className="w-24">Name</div>
                    <TextInput />
                </div>

                <div className="flex items-center space-x-4">
                    <div className="w-24">Description</div>
                    <TextInput />
                </div>

                <div className="flex items-center space-x-4">
                    <div className="w-24">Formula</div>
                    <div>TODO: select dropdown</div>
                </div>
                <div>
                    <Button
                        buttonText="Create"
                        disabled={true}></Button>
                </div>
            </div>
        </div>
    );
};

export default AspectCreate;
