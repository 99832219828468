import { Text, TextStyle, TextType } from '@jameswilliamknight/starbridge-ui';

const HomePage = () => {
    return (
        <div className="p-5 flex space-y-2 flex-col">
            <Text
                textStyle={TextStyle.Primary}
                textType={TextType.LargeHeading}>{`Work`}</Text>
            <div>
                <Text>{`See work on the `}</Text>
                <a
                    className="text-blue-600 underline"
                    href="https://trello.com/b/hIaeG6pc/wwjd">
                    {`Trello Board (wwjd)`}
                </a>
            </div>
        </div>
    );
};

export default HomePage;
