import {
    IconButton,
    Text,
    TextType,
    Tooltip,
    TooltipContent,
    TooltipDensity,
    TooltipTrigger,
    TooltipVariant,
} from '@jameswilliamknight/starbridge-ui';
import { XMarkIcon, DocumentDuplicateIcon } from '@heroicons/react/24/solid';
import useInbox, { InboxItemResponse } from '../data/useInbox';

interface InboxItemProps {
    item: InboxItemResponse;
}

const InboxItem = ({ item }: InboxItemProps) => {
    const { deleteItem } = useInbox();

    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    return (
        <li className="flex justify-between gap-3 items-center bg-neutral-200 dark:bg-neutral-700 p-3 pl-4 rounded-md">
            <Tooltip>
                <TooltipTrigger>
                    <IconButton
                        variant="tertiary"
                        density="compact"
                        icon={<DocumentDuplicateIcon />}
                        onClick={() =>
                            handleCopyToClipboard(item.text)
                        }></IconButton>
                </TooltipTrigger>
                <TooltipContent
                    variant="primary"
                    density="compact"
                    side="left"
                    tooltipContent="Copy to clipboard"
                />
            </Tooltip>

            <Text textType={TextType.XSmall}>{item.text}</Text>

            <Tooltip>
                <TooltipTrigger>
                    <IconButton
                        variant="tertiary"
                        density="compact"
                        icon={<XMarkIcon />}
                        onClick={() => deleteItem(item.id)}></IconButton>
                </TooltipTrigger>
                <TooltipContent
                    variant="primary"
                    density="compact"
                    side="right"
                    tooltipContent="Delete"
                />
            </Tooltip>
        </li>
    );
};

export default InboxItem;
