import axios from 'axios';
import useSWR from 'swr';

const healthProbePath = '/api/v1/probes/ready';

interface IsReadyResponse {
    isReady: boolean;
}

const getIsReady = async (url: string): Promise<boolean> => {
    try {
        const response = await axios.get<IsReadyResponse>(url, {
            timeout: 1000,
        });
        return response.data.isReady;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.message);
        } else {
            console.error('Unexpected error:', error);
        }
        return false;
    }
};

// Custom hook to manage the health probe using SWR
const useHealthProbe = () => {
    const { data, error, isValidating } = useSWR(healthProbePath, getIsReady, {
        refreshInterval: 15000,
    });

    return {
        isReady: !!data,
        isLoading: isValidating,
        isError: !!error,
        isIdle: !error && !isValidating,
    };
};

export default useHealthProbe;
