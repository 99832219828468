import { Handle, HandleProps, Position } from 'reactflow';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import useNodes from './useNodes';
import { useMemo } from 'react';
import { nodeStateVariants } from './nodeStateVariants';
import { NodeState } from './nodeState';

export interface CustomHandleProps extends HandleProps {
    nodeid: string;
}

const CustomHandle = (handleProps: CustomHandleProps) => {
    const { type, position, nodeid: nodeId } = handleProps;
    const { findNode } = useNodes();
    const nodeState = useMemo<NodeState | undefined>(
        () => findNode(nodeId)?.state,
        [findNode, nodeId],
    );

    const nodeStateClassNames = nodeStateVariants({
        state: nodeState,
        handleBackground: nodeState,
    });

    const handleCn = twMerge(
        `border-2 dark:bg-neutral-500 dark:bg-theme-primary-500/25 w-2.5 h-6 flex items-center justify-center duration-500`,
        clsx({
            'transition-colors pointer-events-none opacity-100': true,
            'rounded-e-md rounded-l-none ml-1': position === Position.Left,
            'rounded-s-md rounded-r-none mr-1': position === Position.Right,
            'dark:border-theme-primary-700 dark:border-l-transparent':
                type == 'target',
            'dark:border-theme-primary-700 dark:border-r-transparent':
                type == 'source',
        }),
        nodeStateClassNames,
    );
    return (
        <Handle
            className={handleCn}
            {...handleProps}></Handle>
    );
};

export default CustomHandle;
