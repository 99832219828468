import { Button } from '@jameswilliamknight/starbridge-ui';
import NodeOverlay from './NodeOverlay';
import useNodes from './useNodes';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { cn } from '../../tools/cn';

export interface NodeOverlayBottomProps {
    nodeId: string;
    isHover?: boolean;
}
const NodeOverlayBottom = ({
    nodeId,
    isHover = false,
}: NodeOverlayBottomProps) => {
    const { createLinkedNode } = useNodes();

    return (
        <NodeOverlay
            className={cn(`rounded-full`, {
                'pointer-events-auto': isHover,
                'pointer-events-none': !isHover,
            })}
            isMouseOverParent={isHover}
            nodeId={nodeId}
            position="bottom">
            <div className=" flex space-x-1">
                <Button
                    variant="tertiary"
                    density="compact"
                    icon={
                        <ChevronLeftIcon className="dark:text-neutral-300 w-4 h-4" />
                    }
                    className="text-xs"
                    buttonText="Has Blocker"
                    onClick={() => createLinkedNode(nodeId, 'BLOCKED')}
                />
                <Button
                    variant="tertiary"
                    density="compact"
                    reverse={true}
                    icon={
                        <ChevronRightIcon className="dark:text-neutral-300 w-4 h-4" />
                    }
                    className="text-xs"
                    buttonText="Is Blocking"
                    onClick={() => createLinkedNode(nodeId, 'BLOCKING')}
                />
            </div>
        </NodeOverlay>
    );
};

export default NodeOverlayBottom;
