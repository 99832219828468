import { Outlet } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

interface PageLayoutProps {
    className?: string;
}

const PageLayout = ({ className = '' }: PageLayoutProps) => {
    return (
        <div className="h-full">
            <div className={twMerge('h-full', className)}>
                <Outlet />
            </div>
        </div>
    );
};

export default PageLayout;
