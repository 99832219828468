import { IconButton } from '@jameswilliamknight/starbridge-ui';
import { useNodeLayout } from './useNodeLayout';
import { ViewfinderCircleIcon } from '@heroicons/react/24/solid';
const GraphFitView = () => {
    //const setForceRefreshCounter = useSetAtom(forceRefreshCounterAtom);
    const { triggerSort } = useNodeLayout();
    return (
        <IconButton
            disabled={true}
            variant={'tertiary'}
            density="default"
            onClick={() => {}}>
            <ViewfinderCircleIcon></ViewfinderCircleIcon>
        </IconButton>
    );
};

export default GraphFitView;
