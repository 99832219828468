import { cva } from 'class-variance-authority';

export const nodeStateVariants = cva([], {
    variants: {
        state: {
            NOT_STARTED: 'dark:border-neutral-500 border-neutral-300',
            RESCHEDULED: '',
            STARTED: 'dark:border-theme-primary-700 border-theme-primary-400',
            COMPLETE:
                'dark:border-neutral-700 border-neutral-400 border-dotted',
            INCOMPLETE: 'dark:border-yellow-900 border-amber-300',
            MISSED: '',
        },
        handleBackground: {
            NOT_STARTED: 'dark:bg-neutral-500/25 bg-neutral-300/25',
            RESCHEDULED: '',
            STARTED: 'dark:bg-theme-primary-700/25 bg-theme-primary-400/25',
            COMPLETE:
                'dark:bg-neutral-700 bg-neutral-400 border-solid dark:border-neutral-600 opacity-40',
            INCOMPLETE: 'dark:bg-yellow-900/25 bg-amber-300/25',
            MISSED: '',
        },
    },
    defaultVariants: {
        state: 'INCOMPLETE',
    },
});
