import axios from 'axios';
import useSWR, { mutate } from 'swr';

export interface AspectItem {
    identity: Identity;
    name: string;
    description: string;
    aspectFormula: AspectFormula;
}

export interface AspectFormula {
    identity: Identity;
    name: string;
    description: string;
    formula: string;
    weight: number;
}

export interface Identity {
    value: string;
}

export interface GetAspectItemsResponse {
    items: AspectItem[];
}

const aspectRelativePath = '/api/v1/affixes/aspects';

const getAspectItems = async () => {
    return await axios.get<GetAspectItemsResponse>(aspectRelativePath).then((res) => {
        //console.debug('res', res);
        //console.debug('res.data', res.data);
        return res.data;
    });
};

const addItem = (name: string, description: string) => {
    const payload = { name: name, description: description };
    axios.post(aspectRelativePath, payload);
};

const useAspects = () => {
    const { data, error, isLoading } = useSWR(
        aspectRelativePath,
        getAspectItems,
        {
            refreshInterval: 5000,
        },
    );

    return {
        data,
        create: addItem,
        isLoading,
        isError: error,
        isIdle: !error && !isLoading,
    };
};
export default useAspects;
