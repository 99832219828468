import { atomWithStorage } from 'jotai/utils';

export const currentNodeIdAtom = atomWithStorage<string | undefined>(
    'currentNodeId',
    undefined,
    {
        getItem: (key) => sessionStorage.getItem(key) ?? undefined,
        setItem: (key, value) => {
            if (value === undefined) {
                sessionStorage.removeItem(key);
            } else {
                sessionStorage.setItem(key, value);
            }
        },
        removeItem: (key) => sessionStorage.removeItem(key),
    },
);

