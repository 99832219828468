import { cva } from 'class-variance-authority';

export type CustomNodeDensity = 'default' | 'comfortable' | 'compact';

// file:border-0
// file:bg-transparent
// file:text-sm
// file:font-medium

export const customNodeVariants = cva(
    [
        `
            w-72
        `,
    ],
    {
        variants: {
            density: {
                comfortable: 'text-2xl',
                default: 'text-md',
                compact: 'text-xs',
            },
            disabled: {
                true: 'cursor-not-allowed drop-shadow-none',
                false: '',
            },
        },
        defaultVariants: {
            density: 'default',
            disabled: false,
        },
    },
);
