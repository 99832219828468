import axios from 'axios';
import useSWR, { mutate } from 'swr';

export interface InboxItemResponse {
    id: string;
    text: string;
}

interface GetInboxItemsResponse {
    items: InboxItemResponse[];
}

const inboxRelativePath = '/api/v1/inbox';

const getInboxItems = () => {
    return axios
        .get<GetInboxItemsResponse>(inboxRelativePath)
        .then((res) => res.data);
};

const addItem = (inputValue: string) => {
    axios
        .post(inboxRelativePath, { text: inputValue })
        .then(() => mutate(inboxRelativePath));
};

const deleteItem = (itemId: string) => {
    axios
        .delete(`${inboxRelativePath}/${itemId}`)
        .then(() => mutate(inboxRelativePath));
};

const useInbox = () => {
    const { data, error, isLoading } = useSWR(
        inboxRelativePath,
        getInboxItems,
        {
            refreshInterval: 5000,
        },
    );

    return {
        items: data,
        createItem: (inputValue: string) => addItem(inputValue),
        deleteItem: deleteItem,
        isLoading,
        isError: error,
        isIdle: !error && !isLoading,
    };
};
export default useInbox;
