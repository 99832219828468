import axios from 'axios';
import useSWR from 'swr';

export interface Identity {
    value: string;
}

export interface ResourceItem {
    identity: Identity;
    name: string;
    description: string;
}

export interface GetResourcesResponse {
    resources: ResourceItem[];
}

const resourceRelativePath = '/api/v1/resources';

const getResourceItems = () => {
    return axios.get<GetResourcesResponse>(resourceRelativePath).then((res) => {
        //console.debug('res', res);
        //console.debug('res.data', res.data);
        return res.data;
    });
};

const addItem = (name: string, description: string) => {
    const payload = { name: name, description: description };
    axios.post(resourceRelativePath, payload);
};

const useResources = () => {
    const { data, error, isLoading } = useSWR(
        resourceRelativePath,
        getResourceItems,
        {
            refreshInterval: 5000,
        },
    );

    return {
        data,
        create: addItem,
        isLoading,
        isError: error,
        isIdle: !error && !isLoading,
    };
};

export default useResources;
