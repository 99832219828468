import { DarkLightToggle, Button } from '@jameswilliamknight/starbridge-ui';
import { useLayoutEffect, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import NavItem from '../components/NavItem';
import PageLayout from './PageLayout';
import { SignedIn, SignedOut, useClerk } from '@clerk/clerk-react';
import ConnectionIndicator from '../components/ConnectionIndicator';
import { Bars3Icon } from '@heroicons/react/24/solid';

export default function RootLayout() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        document.body.classList.add('dark:bg-neutral-800');
        document.body.classList.add('bg-neutral-300');
        document.body.classList.add('transition');
        document.body.classList.add('duration-300');
    });

    const navigate = useNavigate();
    const { signOut } = useClerk();

    // Handle clicks outside the menu to close it
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target as Node)
            ) {
                setIsMenuOpen(false);
            }
        }
        function handleResize() {
            if (window.innerWidth >= 1024) {
                setIsMenuOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('resize', handleResize);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="h-dvh flex flex-col">
            <header className="h-12">
                <nav
                    className={`
                        flex
                        items-center
                        justify-between
                        px-2
                        py-2 dark:bg-neutral-800
                        border-b dark:border-neutral-600 border-neutral-400
                    `}>
                    <button
                        className="block lg:hidden"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <Bars3Icon className="h-6 w-6 text-neutral-600 dark:text-neutral-300" />
                    </button>
                    <div
                    onClick={() => setIsMenuOpen(false)}
                        className={`lg:flex lg:flex-row lg:items-center lg:justify-between lg:gap-x-0.5 hidden lg:block`}>
                        <NavItem
                            icon="🏠"
                            to="/"
                            label="Home"
                        />
                        <NavItem
                            icon="📥"
                            to="inbox"
                            label="Inbox"
                        />
                        <NavItem
                            icon="🧙‍♂️"
                            to="aspect"
                            label="Aspects"
                        />
                        <NavItem
                            icon="💻"
                            to="nodes/table"
                            label="Data"
                        />
                        <NavItem
                            icon="📈"
                            to="nodes/graph"
                            label="Flow"
                        />
                        <NavItem
                            icon="❓"
                            to="about"
                            label="About"
                        />
                    </div>
                    <div className="mr-4 flex space-x-4 items-center">
                        <ConnectionIndicator />
                        <div className="flex items-center justify-center">
                            <SignedIn>
                                <Button
                                    variant="tertiary"
                                    buttonText={`Log Out`}
                                    onClick={() => signOut(() => navigate(''))}
                                />
                            </SignedIn>
                            <SignedOut>
                                <Button
                                    variant="primary"
                                    density="compact"
                                    buttonText={`Sign In`}
                                    onClick={() => navigate('sign-in')}
                                />
                            </SignedOut>
                        </div>
                        <DarkLightToggle />
                    </div>
                </nav>
                {isMenuOpen && (
                    <div
                        ref={menuRef}
                        className="lg:hidden fixed top-12 left-0 min-w-1/2 pr-20 h-full bg-neutral-300 dark:bg-neutral-800/50 backdrop-blur-sm shadow-lg z-50 transition-all duration-1000">
                        <div className="flex flex-col p-4 space-y-2">
                            <NavItem
                                icon="🏠"
                                to="/"
                                label="Home"
                            />
                            <NavItem
                                icon="📥"
                                to="inbox"
                                label="Inbox"
                            />
                            <NavItem
                                icon="🧙‍♂️"
                                to="aspect"
                                label="Aspects"
                            />
                            <NavItem
                                icon="💻"
                                to="nodes/table"
                                label="Data"
                            />
                            <NavItem
                                icon="📈"
                                to="nodes/graph"
                                label="Flow"
                            />
                            <NavItem
                                icon="❓"
                                to="about"
                                label="About"
                            />
                        </div>
                    </div>
                )}
            </header>
            <main className="h-full">
                <PageLayout />
            </main>
        </div>
    );
}
