import { Text, TextType } from '@jameswilliamknight/starbridge-ui';
import { ResourceList } from './ResourceList';

export const ResourcesBlock = () => {
    return (
        <div className="flex flex-col">
            <Text
                textType={TextType.LargeHeading}
                className="">
                Resources
            </Text>
            <ResourceList />
        </div>
    );
};
