import React from 'react';
import clsx from 'clsx';

type NodeStatusBadgeProps = {
    state:
        | 'NOT_STARTED'
        | 'RESCHEDULED'
        | 'STARTED'
        | 'COMPLETE'
        | 'INCOMPLETE'
        | 'MISSED';
};

const stateStyles = {
    NOT_STARTED: 'bg-gray-700 border-gray-600 text-gray-400',
    RESCHEDULED: 'bg-yellow-700 border-yellow-600 text-yellow-400',
    STARTED: 'bg-blue-700 border-blue-600 text-blue-400',
    COMPLETE: 'bg-green-700 border-green-600 text-green-400',
    INCOMPLETE: 'bg-orange-700 border-orange-600 text-orange-400',
    MISSED: 'bg-red-700 border-red-600 text-red-400',
};

const NodeStatusBadge: React.FC<NodeStatusBadgeProps> = ({ state }) => {
    return (
        <div
            className={clsx(
                'px-2 py-1 rounded-full border text-white font-mono font-bold text-xs tracking-wide text-center',
                stateStyles[state],
            )}>
            {state.replace('_', ' ')}
        </div>
    );
};

export default NodeStatusBadge;
