import {
    Button,
    Text,
    TextInput,
    TextType,
} from '@jameswilliamknight/starbridge-ui';


export const FormulaCreate = () => {
    return (
        <div className="p-5 flex flex-col space-y-4">
            <Text textType={TextType.SmallHeading}>{`Create a formula`}</Text>
            <div className="w-96 space-y-4 pl-5">
                <div className="flex items-center space-x-4">
                    <div className="w-24">Name</div>
                    <TextInput />
                </div>
                <div className="flex items-center space-x-4">
                    <div className="w-24">Description</div>
                    <TextInput />
                </div>
                <div>
                    <Button
                        buttonText="Create"
                        disabled={true}
                        variant="primary"></Button>
                </div>
            </div>
        </div>
    );
};
