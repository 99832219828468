import axios from 'axios';
import { useAtom } from 'jotai';
import useSWR, { mutate } from 'swr';
import { currentNodeIdAtom as currentNodeIdAtom } from './currentNodeIdAtom';
import { useMemo } from 'react';
import { NodeState } from './nodeState';

const nodeRelativePath = '/api/v1/nodes';

export interface GetNodeResponse {
    nodeId: string;
    parentNodeId?: string;
    title: string;
    description?: string;
    state: NodeState;
    blocks?: GetNodeResponse[];
    blockedBy?: GetNodeResponse[];
    childNodeIds: string[];
}

interface GetAllNodesResponse {
    nodes: GetNodeResponse[];
}

export interface CreateUnlinkedNodeRequest {
    createTitle: string;
    parentNodeId?: string;
    // parentNodeId: string | null;
}

export interface CreateLinkedNodeRequest {
    createTitle: string;
    linkType: 'BLOCKED' | 'BLOCKING';
}

export interface LinkNodesRequest {
    sourceNodeId: string;
    targetNodeId: string;
    linkType: 'BLOCKED' | 'BLOCKING';
}

export interface UnlinkNodesRequest {
    sourceNodeId: string;
    targetNodeId: string;
}

export interface CreateBlockingNodeRequest {
    existingId: string;
    blockingTitle: string;
}

export interface DeleteNodeRequest {
    nodeId: string;
}

export interface UpdateNodeRequest {
    state?: 'INCOMPLETE' | 'STARTED' | 'COMPLETE';
    title?: string;
}

const getAllNodes = async () => {
    return await axios
        .get<GetAllNodesResponse>(nodeRelativePath)
        .then((res) => {
            // console.debug('⚽ res', res);
            //console.debug('⚽ res.data', res.data);
            return res.data;
        });
};

const linkNodes = (payload: LinkNodesRequest) => {
    return axios
        .post<LinkNodesRequest>(`${nodeRelativePath}/link`, payload)
        .then(() => mutate(nodeRelativePath));
};

const unlinkNodes = (payload: UnlinkNodesRequest) => {
    console.debug('💀', payload);
    return axios
        .post<UnlinkNodesRequest>(`${nodeRelativePath}/unlink`, payload)
        .then(() => mutate(nodeRelativePath));
};

const createLinkedNode = (
    existingNodeId: string,
    payload: CreateLinkedNodeRequest,
) => {
    return axios
        .post<CreateLinkedNodeRequest>(
            `${nodeRelativePath}/${existingNodeId}/links`,
            payload,
        )
        .then(() => mutate(nodeRelativePath));
};

const createLinked = (id: string, linkType: 'BLOCKED' | 'BLOCKING'): void => {
    createLinkedNode(id, { createTitle: ``, linkType });
};

const createUnlinkedNode = (payload: CreateUnlinkedNodeRequest) => {
    console.debug('Creating unlinked,', payload);
    return axios
        .post<CreateUnlinkedNodeRequest>(nodeRelativePath, payload)
        .then(() => mutate(nodeRelativePath));
};

const deleteNode = (payload: DeleteNodeRequest) => {
    return axios
        .delete(`${nodeRelativePath}/${payload.nodeId}`)
        .then(() => mutate(nodeRelativePath));
};

const updateNode = (nodeId: string, payload: UpdateNodeRequest) => {
    return axios
        .put(`${nodeRelativePath}/${nodeId}`, payload)
        .then(() => mutate(nodeRelativePath));
};

const findNodeById = (
    data: GetAllNodesResponse | undefined,
    nodeId?: string,
): GetNodeResponse | undefined => {
    return data?.nodes.find((x) => x.nodeId === nodeId);
};

const useNodes = () => {
    const { data, error, isLoading } = useSWR(nodeRelativePath, getAllNodes, {
        refreshInterval: 15000,
    });

    const [currentNodeId, setCurrentNodeId] = useAtom(currentNodeIdAtom);

    const dataCurrentView = useMemo((): GetNodeResponse[] => {
        const isRoot = currentNodeId === undefined;
        const filteredNodes =
            data?.nodes.filter((node) =>
                isRoot
                    ? node.parentNodeId === null
                    : node.parentNodeId === currentNodeId,
            ) ?? [];
        return filteredNodes;
    }, [data, currentNodeId]);

    return {
        data,
        dataCurrentView,
        navigateToParentNode: () => {
            const currentNode = findNodeById(data, currentNodeId);
            setCurrentNodeId(currentNode?.parentNodeId);
        },
        currentNode: findNodeById(data, currentNodeId),
        currentNodeParentTitle: findNodeById(
            data,
            findNodeById(data, currentNodeId)?.parentNodeId,
        )?.title,
        findNode: (nodeId: string) => findNodeById(data, nodeId),
        linkNodes,
        unlinkNodes,
        createLinkedNode: createLinked,
        createUnlinkedNode,
        deleteNode,
        updateNode,
        isLoading,
        isError: error,
        isIdle: !error && !isLoading,
    };
};

export default useNodes;
