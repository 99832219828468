import 'reactflow/dist/style.css';
import { IconButton, TextEdit } from '@jameswilliamknight/starbridge-ui';
import { NodeProps } from 'reactflow';
import { useEffect, useRef, useState } from 'react';
import { TrashIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/solid';
import useNodes from './useNodes';
import useNodeAdjacentHandles from './useNodeAdjacentHandles';
import StatusControl from './StatusControl';
import { useAtom } from 'jotai';
import { twMerge } from 'tailwind-merge';
import NodeOverlay from './NodeOverlay';
import { hoverNodeIdAtom } from './hoverNodeIdAtom';
import NodeOverlayBottom from './NodeOverlayBottom';
import { currentNodeIdAtom } from './currentNodeIdAtom';
import { cn } from '../../tools/cn';
import { customNodeVariants } from './customNodeVariants';
import { nodeStateVariants } from './nodeStateVariants';

const CustomNode = (props: NodeProps) => {
    const { id, data } = props;
    const { state } = data;
    const { deleteNode, updateNode } = useNodes();
    const [, setCurrentNodeId] = useAtom(currentNodeIdAtom);
    const nodeRef = useRef<HTMLDivElement>(null);
    const [isHover, setIsHover] = useState(false);
    const [hoverNodeId, setHoverNodeId] = useAtom(hoverNodeIdAtom);

    // input
    const [nodeTitle, setNodeTitle] = useState<string>(props.data.label || '');
    const inputRef = useRef<HTMLTextAreaElement>(null);

    // When other nodes focus, we "blur" this node.
    useEffect(() => {
        if (hoverNodeId !== props.id) {
            // NodeOverlay children listen to this Node's hover state.
            // If NodeOverlay hasn't been hovered, it should go invisible
            //   when a different Node gets 'onMouseEnter' raised.
            setIsHover(false);
        }
    }, [hoverNodeId, props.id]);

    const { handleSource, handleTarget } = useNodeAdjacentHandles(props.id);

    const hasChildren = data?.children?.length ?? 0 > 0;

    const nodeClassNames = twMerge(
        'border-2 dark:border-neutral-700 rounded-xl',
        'cursor-default',
        'shadow-2xl',
        'bg-neutral-500/75 dark:bg-neutral-800/75',
        nodeStateVariants({ state: state }),
        customNodeVariants({ density: 'default' }),
    );

    return (
        <div className="nodrag w-full">
            {handleSource}
            {handleTarget}

            {/* Node Content */}
            <div
                ref={nodeRef}
                onMouseEnter={() => {
                    setIsHover(true);
                    setHoverNodeId(props.id);
                }}
                onMouseLeave={() => {
                    setTimeout(() => {
                        setIsHover(false);
                    }, 1000);
                }}
                className={cn(`flex flex-col`, nodeClassNames)}>
                <div className={cn(`h-full w-72`)}>
                    <div
                        className={`flex justify-center p-1 px-3 w-full`}
                        // onMouseEnter={() => console.debug('🎉')}
                    >
                        <TextEdit
                            characterLimit={1000}
                            density="default"
                            ref={inputRef}
                            value={nodeTitle}
                            // onClick={() => console.debug('🐁 click')}
                            onChange={(e) => {
                                setNodeTitle(e.currentTarget.value);
                            }}
                            onBlur={() => {
                                console.debug(
                                    '🚀 lost focus, input is now:',
                                    nodeTitle,
                                );
                                updateNode(id, { title: nodeTitle });
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    if (e.ctrlKey) {
                                        if (
                                            state === 'NOT_STARTED' ||
                                            state === 'INCOMPLETE'
                                        ) {
                                            updateNode(id, {
                                                state: 'STARTED',
                                            });
                                            setCurrentNodeId(id);
                                        }
                                    }
                                    e.preventDefault();
                                    e.currentTarget.blur();
                                }
                            }}
                            className="break-words cursor-text"
                        />
                    </div>
                </div>
            </div>

            {/* Children Count Indicator */}
            <div
                className={cn(
                    'absolute left-1/2 transform -translate-x-1/2 -top-2 flex space-x-1 transition-opacity duration-300',
                    { 'opacity-0': isHover, 'opacity-100': !isHover },
                )}>
                {data?.children?.map((child: string) => (
                    <div
                        key={`child-${child}`}
                        className="w-2 h-1  rounded-full bg-theme-primary-500"></div>
                ))}{' '}
            </div>

            {/* Top Overlay */}
            <NodeOverlay
                nodeId={id}
                isMouseOverParent={isHover}
                position="top">
                <StatusControl
                    id={id}
                    state={state}
                />

                {state !== 'COMPLETE' || hasChildren ? (
                    <IconButton
                        variant={hasChildren ? 'primary' : 'tertiary'}
                        density="compact"
                        onClick={() => setCurrentNodeId(id)}>
                        <ArrowsPointingOutIcon />
                    </IconButton>
                ) : (
                    <></>
                )}

                {state !== 'COMPLETE' && (
                    <IconButton
                        variant="tertiary"
                        density="compact"
                        onClick={() => deleteNode({ nodeId: id })}>
                        <TrashIcon />
                    </IconButton>
                )}
            </NodeOverlay>

            {/* Bottom Overlay */}
            {state !== 'COMPLETE' && (
                <NodeOverlayBottom
                    isHover={isHover}
                    nodeId={id}
                />
            )}
        </div>
    );
};

export default CustomNode;
