import {
    IconButton,
    Tooltip,
    TooltipVariant,
    TooltipDensity,
    TooltipProvider,
    TooltipTrigger,
    TooltipContent,
} from '@jameswilliamknight/starbridge-ui';
import { useNodeLayout } from './useNodeLayout';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/solid';

const GraphSort = () => {
    const { triggerSort } = useNodeLayout();
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger className="cursor-not-allowed">
                    <IconButton
                        variant={'tertiary'}
                        density="default"
                        onClick={triggerSort}>
                        <ArrowPathRoundedSquareIcon />
                    </IconButton>
                </TooltipTrigger>
                <TooltipContent
                    variant={'primary' as TooltipVariant}
                    density={'default' as TooltipDensity}
                    side="bottom"
                    tooltipContent="Sort the graph"
                />
            </Tooltip>
        </TooltipProvider>
    );
};

export default GraphSort;
