import {
    IconButton,
    TextFormatted,
    Tooltip,
    TooltipContent,
    TooltipDensity,
    TooltipProvider,
    TooltipTrigger,
    TooltipVariant,
} from '@jameswilliamknight/starbridge-ui';
import { useAtom } from 'jotai';
import { currentNodeIdAtom } from './currentNodeIdAtom';
import useNodes from './useNodes';
import {
    SquaresPlusIcon,
    ChevronDoubleUpIcon,
    ChevronUpIcon,
} from '@heroicons/react/24/solid';
import GraphFitView from './GraphFitView';
import { cn } from '../../tools/cn';
import GraphSort from './GraphSort';
import NavItemTitle from './Nav/NavItemTitle';

const NodeNavBar = () => {
    const { navigateToParentNode, createUnlinkedNode, currentNodeParentTitle } =
        useNodes();
    const [currentNodeId, setCurrentNodeId] = useAtom(currentNodeIdAtom);
    const { currentNode } = useNodes();

    const isRoot = !currentNode?.title;
    const isParentRoot = !currentNodeParentTitle;

    return (
        <TooltipProvider>
            <div className="p-2  flex items-center space-x-4">
                <div className="p-1 bg-neutral-500/25 dark:bg-neutral-700/25 rounded-full flex items-center space-x-4 px-3">
                    <GraphFitView />
                    <GraphSort />
                    {!isRoot && (
                        <Tooltip>
                            <TooltipTrigger>
                                <IconButton
                                    variant="tertiary"
                                    /*  disabled={isRoot} */
                                    density="default"
                                    onClick={() => {
                                        console.debug(
                                            `🫎 resetting parent node id to undefined`,
                                        );
                                        setCurrentNodeId(undefined);
                                    }}>
                                    <ChevronDoubleUpIcon
                                        className={`${!isParentRoot ? 'text-theme-primary-500' : ''}`}></ChevronDoubleUpIcon>
                                </IconButton>
                            </TooltipTrigger>
                            <TooltipContent
                                variant={'primary' as TooltipVariant}
                                density={'default' as TooltipDensity}
                                side="bottom"
                                tooltipContent="Back to Top Level"
                            />
                        </Tooltip>
                    )}
                    {!isRoot && (
                        <Tooltip>
                            <TooltipTrigger>
                                <IconButton
                                    variant="tertiary"
                                    disabled={isRoot}
                                    density="default"
                                    onClick={() => {
                                        navigateToParentNode();
                                    }}>
                                    <ChevronUpIcon></ChevronUpIcon>
                                </IconButton>
                            </TooltipTrigger>
                            <TooltipContent
                                variant={'primary' as TooltipVariant}
                                density={'default' as TooltipDensity}
                                side="bottom"
                                tooltipContent="Back to Parent Node"
                            />
                        </Tooltip>
                    )}
                </div>
                <NavItemTitle isRoot={isRoot} />
                <Tooltip>
                    <TooltipTrigger>
                        <div className="p-1 bg-neutral-500/25 dark:bg-neutral-700/25 rounded-full flex items-center space-x-4 px-3">
                            <IconButton
                                variant="tertiary"
                                density="default"
                                onClick={() =>
                                    createUnlinkedNode({
                                        createTitle: ``,
                                        parentNodeId: currentNodeId,
                                    })
                                }>
                                <SquaresPlusIcon></SquaresPlusIcon>
                            </IconButton>
                        </div>
                    </TooltipTrigger>
                    <TooltipContent
                        variant={'primary' as TooltipVariant}
                        density={'default' as TooltipDensity}
                        side="bottom"
                        tooltipContent="Create Node"
                    />
                </Tooltip>
            </div>
        </TooltipProvider>
    );
};

export default NodeNavBar;
