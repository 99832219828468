import Dagre from '@dagrejs/dagre';
import { Node, Edge, Position } from 'reactflow';

const dagreGraph = new Dagre.graphlib.Graph();

export const formatNodes = (
    nodes: Node[],
    edges: Edge[],
    isHorizontal: boolean,
    isReinit: boolean,
) => {
    console.debug('🦥 isReinit:', isReinit);
    const dagreGraphRef = isReinit ? new Dagre.graphlib.Graph() : dagreGraph;

    dagreGraphRef.setDefaultEdgeLabel(() => ({}));

    const direction = isHorizontal ? 'LR' : 'TB';
    dagreGraphRef.setGraph({ rankdir: direction });

    // Default if not provided
    const widthDefault = 500;
    const heightDefault = 150;

    nodes.forEach((node) => {
        const width = node.width || widthDefault;
        const height = node.height || heightDefault;
        dagreGraphRef.setNode(node.id, { width, height });
    });

    edges.forEach((edge) => {
        dagreGraphRef.setEdge(edge.source, edge.target);
    });

    Dagre.layout(dagreGraphRef);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraphRef.node(node.id);
        node.targetPosition = isHorizontal ? Position.Right : Position.Top;
        node.sourcePosition = isHorizontal ? Position.Left : Position.Bottom;

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
            x: nodeWithPosition.x - widthDefault / 2,
            y: nodeWithPosition.y - heightDefault / 2,
        };

        return node;
    });

    return { nodes, edges };
};
