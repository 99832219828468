import { Text, TextType } from '@jameswilliamknight/starbridge-ui';

import useResources from '../data/useResources';

export const ResourceList = () => {
    const { data, isLoading } = useResources();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (!data?.resources.length) {
        return (
            <div className="p-10">
                <Text textType={TextType.XSmall}>{`No resources found.`}</Text>
            </div>
        );
    }

    return (
        <div className="flex flex-wrap">
            {data?.resources?.map((item) => (
                <div
                    key={item.identity.value}
                    className="m-2 w-96 rounded-xl p-4 border">
                    <Text
                        textType={TextType.SmallHeading}
                        className="pl-5">
                        {'🎮  '}
                        {formatString(item.name)}
                    </Text>
                    <Text className="px-2">{item.description}</Text>
                </div>
            ))}
        </div>
    );
};

function formatString(input: string): string {
    // Add a space before capital letters
    const spaced = input.replace(/([A-Z])/g, ' $1');

    // Split by word
    const words = spaced.split(/\s+/);

    // Join the words by a single space and return
    return words.join(' ').trim();
}
