import CustomHandle from './CustomHandle';
import { Position } from 'reactflow';

const useNodeAdjacentHandles = (nodeId: string) => {
    const handleSource = (
        <CustomHandle
            type="target"
            position={Position.Left}
            id={`${nodeId}_left`}
            nodeid={nodeId}
        />
    );

    const handleTarget = (
        <CustomHandle
            type="source"
            position={Position.Right}
            id={`${nodeId}_right`}
            nodeid={nodeId}
        />
    );

    return {
        handleSource,
        handleTarget,
    };
};

export default useNodeAdjacentHandles;
