import React from 'react';
import { ReactFlowProvider } from 'reactflow';
import Flow from './Flow';

const FlowWithProvider = () => {
    return (
        <ReactFlowProvider>
            <Flow />
        </ReactFlowProvider>
    );
};

export default FlowWithProvider;
