import { atom, useAtom } from 'jotai';

type Coordinates = {
    x: number;
    y: number;
    zoom: number;
};

type LayoutState = {
    forceSortCounter: number;
    coordinates: Coordinates;
};

const layoutAtom = atom<LayoutState>({
    forceSortCounter: 0,
    coordinates: { x: 0, y: 0, zoom: 1 },
});

const useNodeLayout = () => {
    const [layout, setLayout] = useAtom(layoutAtom);

    const triggerSort = () => {
        setLayout((prevLayout) => ({
            ...prevLayout,
            forceSortCounter: prevLayout.forceSortCounter + 1,
        }));
    };

    const setCoordinates = (newCoordinates: Coordinates) => {
        setLayout((prevLayout) => ({
            ...prevLayout,
            coordinates: newCoordinates,
        }));
    };

    return {
        forceSortCounter: layout.forceSortCounter,
        coordinates: layout.coordinates,
        triggerSort,
        setCoordinates,
    };
};

export { useNodeLayout };

// import { atom, useAtom } from 'jotai';

// type Coordinates = {
//     x: number;
//     y: number;
// };

// type LayoutState = {
//     forceRefreshCounter: number;
//     coordinates: Coordinates;
// };

// const layoutAtom = atom<LayoutState>({
//     forceRefreshCounter: 0,
//     coordinates: { x: 0, y: 0 },
// });

// const useNodeLayout = () => {
//     const [layout, setLayout] = useAtom(layoutAtom);

//     const setForceRefreshCounter = (newCounter: number) => {
//         setLayout((prevLayout) => ({
//             ...prevLayout,
//             forceRefreshCounter: newCounter,
//         }));
//     };

//     const setCoordinates = (newCoordinates: Coordinates) => {
//         setLayout((prevLayout) => ({
//             ...prevLayout,
//             coordinates: newCoordinates,
//         }));
//     };

//     return {
//         forceRefreshCounter: layout.forceRefreshCounter,
//         setForceRefreshCounter,
//         coordinates: layout.coordinates,
//         setCoordinates,
//     };
// };

// export { useNodeLayout };

// ---------------------------------------------------------------------

// import { atom } from 'jotai';

// export const forceRefreshCounterAtom = atom<number>(0);
