// import { Link, ToPathOption } from "@tanstack/react-router";

import { Button } from '@jameswilliamknight/starbridge-ui';
import React from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

interface NavItemProps {
    icon?: React.ReactNode;
    to: string;
    label: string;
}

const NavItem = ({ to, label, icon = undefined }: NavItemProps) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleClick = () => {
        navigate(to);
    };

    return (
        <NavLink
            to={to}
            onClick={handleClick}>
            <Button
                density="compact"
                buttonText={label}
                variant={`/${to}` == pathname ? 'primary' : 'tertiary'}
                icon={icon}></Button>
        </NavLink>
    );
};

export default NavItem;
