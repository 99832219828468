import React from 'react';
import useHealthProbe from '../data/useHealthProbe';

const ConnectionIndicator = () => {
    const { isReady } = useHealthProbe();

    const text = isReady ? 'Connected' : 'Connecting';
    const textColour = isReady
        ? 'dark:text-neutral-700 text-neutral-400'
        : 'text-red-500 animate-pulse';

    return (
        <div className="select-none font-mono">
            <span className={textColour}>{text}</span>
        </div>
    );
};

export default ConnectionIndicator;
