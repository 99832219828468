import { Text, TextStyle, TextType } from '@jameswilliamknight/starbridge-ui';

const AboutPage = () => {
    return (
        <div className="p-5 flex space-y-2 flex-col">
            <Text
                textStyle={TextStyle.Primary}
                textType={TextType.LargeHeading}>{`About`}</Text>
            <Text textType={TextType.SmallHeading}>{`Starbridge`}</Text>{' '}
            <Text>{`James Knight, 2024. Not Registered.`}</Text>
        </div>
    );
};

export default AboutPage;
