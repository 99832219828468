import { Text, TextType } from '@jameswilliamknight/starbridge-ui';
import { FormulaCreate } from './FormulaCreate';

export const FormulaCreateBlock = () => {
    return (
        <div className="w-auto">
            <Text
                textType={TextType.LargeHeading}
                className="">
                Formula
            </Text>
            <FormulaCreateDescription />
            {FormulaCreate()}
        </div>
    );
};


const FormulaCreateDescription = () => {
    return (
        <div className="flex flex-col p-5 space-y-4">
            <Text>
                {`A formula is a component of a calculation. It wraps formulae.`}
            </Text>
            <Text>{`For instance:`}</Text>
            <Text className="pl-5">
                {`"Aesthetic" is an formula when calculating the priority of a UI mockup. It wraps a 1.5x multiplier.`}
            </Text>
        </div>
    );
};
