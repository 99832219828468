import {
    Tooltip,
    TooltipTrigger,
    TextFormatted,
    TooltipContent,
    TooltipVariant,
    TooltipDensity,
} from '@jameswilliamknight/starbridge-ui';
import React from 'react';
import { cn } from '../../../tools/cn';
import useNodes from '../useNodes';

interface NavItemTitleProps {
    isRoot: boolean;
}

const NavItemTitle = ({ isRoot }: NavItemTitleProps) => {
    const { currentNode } = useNodes();

    return (
        <Tooltip>
            <TooltipTrigger>
                <div className="p-2 px-3 bg-neutral-500/25 dark:bg-neutral-700/25 rounded-xl flex items-center space-x-8">
                    {/* <Text
textType={TextType.Default}
className="px-4 select-none dark:text-theme-primary-500">
{!isRoot ? currentNode.title : 'Root Level'}
</Text> */}
                    <TextFormatted
                        value={!isRoot ? currentNode!.title : 'Root Level'}
                        density="default"
                        className={cn(
                            'select-none',
                            'break-words',
                            'pointer-events-auto',
                            'transition-opacity duration-500',
                        )}></TextFormatted>
                </div>
            </TooltipTrigger>
            <TooltipContent
                variant={'primary' as TooltipVariant}
                density={'default' as TooltipDensity}
                side="bottom"
                tooltipContent="Current Node"
            />
        </Tooltip>
    );
};

export default NavItemTitle;
