import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import './app/App.css';

// Disable SSR when running the dev server
export const ssr = false;

const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <div>
            <StrictMode>
                <App />
            </StrictMode>
        </div>,
    );
}
