import FlowWithProvider from '../components/FlowWithProvider';
import { useAtomValue } from 'jotai';
import { currentNodeIdAtom } from '../components/nodes/currentNodeIdAtom';
import NodeNavBar from '../components/nodes/NodeNavBar';

const GraphPage = () => {
    const currentNodeId = useAtomValue(currentNodeIdAtom);

    console.debug(`rendering graph page in parent node, id='${currentNodeId}'`);

    return (
        <div className="w-full h-full appearance-none bg-neutral-100">
            <div className="absolute z-10 left-0 top-20 items-center justify-center w-dvw flex space-x-4">
                <NodeNavBar />
            </div>

            <FlowWithProvider />
        </div>
    );
};

export default GraphPage;
