import { Card, Text } from '@jameswilliamknight/starbridge-ui';

import useAspects from '../data/useAspects';
import { TextType } from '@jameswilliamknight/starbridge-ui';

const AspectList = () => {
    const { data, isLoading } = useAspects();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex flex-col px-3">
            <Text textType={TextType.LargeHeading}>Aspects</Text>
            <div className="flex flex-wrap">
                {data?.items?.map((item) => (
                    <div
                        key={item.identity.value}
                        className="m-2 w-96 rounded-xl p-4">
                        <Text textType={TextType.LargeHeading}>
                            {'⭐  '}
                            {formatString(item.name)}
                        </Text>
                        <Text className="px-2 text-neutral-600">
                            {item.description}
                        </Text>
                        <Card className="mt-2 flex flex-col space-y-2 rounded-xl border p-6">
                            <div className="flex space-x-2">
                                <Text>Name: </Text>
                                <Text textType={TextType.Code}>
                                    {formatString(item.aspectFormula.name)}
                                </Text>
                            </div>
                            <div className="flex space-x-2">
                                <Text>Desc: </Text>
                                <Text textType={TextType.Code}>
                                    {item.aspectFormula.description}
                                </Text>
                            </div>
                            <div className="flex space-x-2">
                                <Text>Formula: </Text>
                                <Text textType={TextType.Code}>
                                    {item.aspectFormula.formula}
                                </Text>
                            </div>
                            <div className="flex space-x-2">
                                <Text>Weight: </Text>
                                <Text textType={TextType.Code}>
                                    {item.aspectFormula.weight}
                                </Text>
                            </div>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AspectList;

function formatString(input: string): string {
    // Add a space before capital letters
    const spaced = input.replace(/([A-Z])/g, ' $1');

    // Split by word
    const words = spaced.split(/\s+/);

    // Join the words by a single space and return
    return words.join(' ').trim();
}
