import {
    Button,
    Text,
    TextInput,
    TextType,
} from '@jameswilliamknight/starbridge-ui';


export const ResourceCreate = () => {
    return (
        <div className="pl-5">
            <Text
                textType={TextType.SmallHeading}
                className="mb-4 mt-4">
                Create a resource:
            </Text>
            <div className="w-96 space-y-4 pl-5">
                <div className="flex items-center space-x-4">
                    <Text className="w-24">Name</Text>
                    <TextInput />
                </div>
                <div className="flex items-center space-x-4">
                    <Text className="w-24">Description</Text>
                    <TextInput />
                </div>
                <Button
                    buttonText="Create"
                    disabled={true}
                    variant="primary"></Button>
            </div>
        </div>
    );
};
