import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, {
    Background,
    useNodesState,
    useEdgesState,
    BackgroundVariant,
    Node,
    Edge,
    useReactFlow,
    MarkerType,
    addEdge,
    Connection,
    useOnSelectionChange,
} from 'reactflow';
import ConnectionLine from '../components/nodes/CustomConnectionLine';
import CustomNode from '../components/nodes/CustomNode';
import CustomEdge from '../components/nodes/CustomEdge';
import useNodes from './nodes/useNodes';
import { useSetAtom } from 'jotai';
import { formatNodes } from './nodes/Layout';
import { useNodeLayout } from './nodes/useNodeLayout';
import { cn } from '../tools/cn';

import './Background.css';
import { selectedEdgesAtom } from './nodes/selectedEdgesAtom';

const Flow = () => {
    const reactFlowInstance = useReactFlow();
    const { forceSortCounter: forceRefreshCounter } = useNodeLayout();
    const prevForceRefreshCounterRef = useRef(forceRefreshCounter);
    const { dataCurrentView, linkNodes } = useNodes();
    const [viewport, setViewport] = useState({ x: 0, y: 0, zoom: 1 });
    const { setCoordinates } = useNodeLayout();

    const [nodes, setNodes] = useNodesState<Node>([]);
    const [edges, setEdges] = useEdgesState<Edge>([]);
    const nodeTypes = useMemo(() => ({ customNode: CustomNode }), []);
    const edgeTypes = useMemo(() => ({ customEdge: CustomEdge }), []);
    const setSelectedEdges = useSetAtom(selectedEdgesAtom);

    const onConnect = useCallback(
        (params: Edge | Connection) =>
            setEdges((eds) => {
                console.debug(`⚙️ ${params.source} -> ${params.target}`);
                linkNodes({
                    linkType: 'BLOCKING',
                    sourceNodeId: params.source!,
                    targetNodeId: params.target!,
                });
                return addEdge(params, eds);
            }),
        [linkNodes, setEdges],
    );

    // const computeNodes = useCallback((): Node[] => {
    //     console.debug('🌠 computeNodes, nodes = ', dataCurrentView);
    //     return (
    //         dataCurrentView?.map((x) => ({
    //             id: x.nodeId,
    //             type: 'customNode',
    //             position: { x: 0, y: 0 },
    //             data: {
    //                 label: x.title,
    //                 isHorizontal: true,
    //                 state: x.state,
    //                 children: x.childNodeIds,
    //             },
    //         })) || []
    //     );
    // }, [dataCurrentView]);

    const computeNodes = useCallback((): Node[] => {
        console.debug('🌠 computeNodes, nodes = ', dataCurrentView);

        const customNodes =
            dataCurrentView?.map((x) => ({
                id: x.nodeId,
                type: 'customNode',
                position: { x: 0, y: 0 },
                data: {
                    label: x.title,
                    isHorizontal: true,
                    state: x.state,
                    children: x.childNodeIds,
                },
            })) || [];

        return customNodes;
    }, [dataCurrentView]);

    const computeEdges = useCallback((): Edge[] => {
        return (
            dataCurrentView?.flatMap(
                (node) =>
                    node.blocks?.map((block) => ({
                        id: `e${node.nodeId}-${block.nodeId}`,
                        source: node.nodeId,
                        target: block.nodeId,
                        markerEnd: {
                            type: MarkerType.Arrow,
                            width: 30,
                            height: 30,
                        },
                        sourceHandle: `${node.nodeId}_right`,
                        targetHandle: `${block.nodeId}_left`,
                        data: { isHorizontal: true },
                        type: 'customEdge',
                    })) || [],
            ) || []
        );
    }, [dataCurrentView]);

    useEffect(() => {
        if (!dataCurrentView) return;

        // prevForceRefreshCounterRef
        const isForceRefresh =
            prevForceRefreshCounterRef.current !== forceRefreshCounter;
        prevForceRefreshCounterRef.current = forceRefreshCounter;

        const computedNodes = computeNodes();
        const computedEdges = computeEdges();

        const formatted = formatNodes(
            computedNodes,
            computedEdges,
            true,
            isForceRefresh,
        );
        setNodes(formatted.nodes);
        setEdges(formatted.edges);
    }, [
        computeEdges,
        computeNodes,
        dataCurrentView,
        reactFlowInstance,
        setEdges,
        setNodes,
        forceRefreshCounter,
    ]);

    useEffect(() => {
        console.trace(`👻 ${forceRefreshCounter}`);
        setTimeout(() => {
            window.requestAnimationFrame(() => {
                reactFlowInstance.fitView({ duration: 600 });
            });
        }, 100);
    }, [forceRefreshCounter, reactFlowInstance]);

    useOnSelectionChange({
        onChange: (selectChangedParams) => {
            const selectedEdges = selectChangedParams.edges.map(
                (edge) => edge.id,
            );
            console.debug('🔔 selectedEdges:', selectedEdges);
        },
    });

    return (
        <ReactFlow
            className={cn(
                `dark:bg-neutral-800 bg-neutral-200`,
                `items-center justify-center`,
                `hover:cursor-default`,
                'bg-cover',
                'cursor-default',
            )}
            nodes={nodes ?? []}
            edges={edges ?? []}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onEdgeClick={(_, edge) => {
                const edgeId = edge.id;
                setSelectedEdges([edgeId]);
            }}
            onConnect={onConnect}
            panOnDrag={[0, 1, 2]}
            connectionLineComponent={ConnectionLine}
            fitView
            onPaneClick={() => {
                setEdges((edges) =>
                    edges.map((edge) => ({
                        ...edge,
                        selected: false,
                    })),
                );
                setSelectedEdges([]);
            }}
            onMove={(event, viewport) => setViewport(viewport)}
            onMoveEnd={(event, viewport) =>
                setCoordinates({
                    x: viewport.x,
                    y: viewport.y,
                    zoom: viewport.zoom,
                })
            }
            proOptions={{ hideAttribution: true }}>
            <Background
                color="#1a2c32"
                className="hero-pattern-bg dark:bg-neutral-800"
                style={{
                    // backgroundColor: '#1a2c32',
                    backgroundRepeat: 'repeat',
                    //
                    // Normal Mode
                    backgroundPositionX: viewport.x * 0.8,
                    backgroundPositionY: viewport.y * 0.8,
                    //
                    // Vertigo Mode, try find a way to toggle this on for shits and gigs.
                    //backgroundPositionX: viewport.x * (0.8 - viewport.zoom),
                    //backgroundPositionY: viewport.y * (0.8 - viewport.zoom),
                    //
                    backgroundSize: `${viewport.zoom * 15}%`,
                }}
                variant={BackgroundVariant.Dots}></Background>
        </ReactFlow>
    );
};

export default Flow;
