import axios from 'axios';
import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';
import { SWRConfig } from 'swr';
import { ClerkProvider } from '@clerk/clerk-react';

import { wwjdBffUrlSecure, wwjdUrlProduction } from './constants';
import RootLayout from './layouts/RootLayout';
import AboutPage from './pages/AboutPage';
import AspectPage from './pages/AspectPage';
import ErrorPage from './pages/ErrorPage';
import GraphPage from './pages/GraphPage';
import HomePage from './pages/HomePage';
import InboxPage from './pages/InboxPage';
import NodeTablePage from './pages/NodeTablePage';
import SignInPage from './pages/SignInPage';

const isProduction = true;
axios.defaults.baseURL = isProduction ? wwjdUrlProduction : wwjdBffUrlSecure;

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path="/"
            element={<RootLayout />}
            errorElement={<ErrorPage />}>
            <Route
                index
                element={<HomePage />}
            />
            <Route
                path="inbox"
                element={<InboxPage />}
            />
            <Route
                path="aspect"
                element={<AspectPage />}
            />
            <Route
                path="nodes/table"
                element={<NodeTablePage />}
            />
            <Route
                path="nodes/graph"
                element={<GraphPage />}
            />
            <Route
                path="about"
                element={<AboutPage />}
            />
            <Route
                path="sign-in"
                element={<SignInPage />}
            />
        </Route>,
    ),
);
const clerkPubKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY as string;
if (!clerkPubKey) {
    throw new Error('Missing Publishable Key');
}

export default function App() {
    return (
        <ClerkProvider publishableKey={clerkPubKey}>
            {/* https://swr.vercel.app/docs/global-configuration */}
            <SWRConfig
                value={{
                    refreshInterval: 3000,
                    //
                    // https://swr.vercel.app/docs/data-fetching#axios
                    fetcher: (resource, init) =>
                        fetch(resource, init).then((res) => res.json()),
                }}>
                <RouterProvider router={router} />
            </SWRConfig>
        </ClerkProvider>
    );
}
