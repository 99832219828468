import 'reactflow/dist/style.css';
import { IconButton } from '@jameswilliamknight/starbridge-ui';
import {
    PlayIcon,
    PauseIcon,
    CheckIcon,
    BackwardIcon,
} from '@heroicons/react/24/solid';
import useNodes from './useNodes';
import { ReactNode } from 'react';

export interface StatusControlProps {
    id: string;
    state: string;
}

const StatusControl = ({ id, state }: StatusControlProps) => {
    const { updateNode } = useNodes();

    const parkedButton = (
        <IconButton
            variant="tertiary"
            density="compact"
            onClick={() => updateNode(id, { state: 'STARTED' })}>
            <PlayIcon></PlayIcon>
        </IconButton>
    );

    const inProgress = (
        <div className="flex space-x-4">
            <IconButton
                variant="tertiary"
                density="compact"
                onClick={() => updateNode(id, { state: 'INCOMPLETE' })}>
                <PauseIcon></PauseIcon>
            </IconButton>
            <IconButton
                variant="tertiary"
                density="compact"
                onClick={() => updateNode(id, { state: 'COMPLETE' })}>
                <CheckIcon></CheckIcon>
            </IconButton>
        </div>
    );

    const restartButton = (
        <IconButton
            variant="tertiary"
            density="compact"
            onClick={() => updateNode(id, { state: 'INCOMPLETE' })}>
            <BackwardIcon />
        </IconButton>
    );

    let buttonUsed: ReactNode;
    switch (state) {
        case 'NOT_STARTED':
        case 'INCOMPLETE':
            buttonUsed = parkedButton;
            break;
        case 'STARTED':
            buttonUsed = inProgress;
            break;
        case 'COMPLETE':
            buttonUsed = restartButton;
            break;

        default:
            break;
    }

    return <div>{buttonUsed}</div>;
};

export default StatusControl;
