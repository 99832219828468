import AspectCreate from '../components/AspectCreate';
import AspectList from '../components/AspectList';
import { FormulaCreateBlock } from '../components/FormulaCreateBlock';
import { ResourceCreateBlock } from '../components/ResourceCreateBlock';
import { ResourcesBlock } from '../components/ResourcesBlock';
//import useAspect from '../data/useAspects';

const AspectPage = () => {
    //const { data } = useAspect();
    return (
        <div className="space-y-10 p-5">
            <ResourcesBlock />
            <ResourceCreateBlock />
            <FormulaCreateBlock />
            <AspectList />
            <AspectCreate />
        </div>
    );
};

export default AspectPage;
