import { Text, Button, TextStyle } from '@jameswilliamknight/starbridge-ui';
import useNodes from '../components/nodes/useNodes';
import { currentNodeIdAtom } from '../components/nodes/currentNodeIdAtom';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import NodeStatusBadge from '../components/NodeStatusBadge';
import clsx from 'clsx';

const NodeTablePage = () => {
    const [currentNodeId, setCurrentNodeId] = useAtom(currentNodeIdAtom);
    const navigate = useNavigate();

    const handleNavigateClick = (nodeId: string) => {
        setCurrentNodeId(nodeId);
        navigate('/nodes/graph');
    };

    const { data } = useNodes();
    return (
        <div className="w-full h-full flex justify-center items-center hero-pattern-bg">
            <table className="table-auto border-collapse">
                <thead>
                    <tr className="bg-neutral-800 text-white">
                        <th className="p-2 px-3 border-b border-neutral-600 text-left">
                            <Text>Node</Text>
                        </th>
                        <th className="p-2 px-3 border-b border-neutral-600">
                            <Text>Status</Text>
                        </th>
                        <th className="p-2 px-3 border-b border-neutral-600">
                            <Text>Graph</Text>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data?.nodes
                        ?.filter((node) => !node.parentNodeId)
                        ?.map((node, index) => (
                            <tr
                                key={node.nodeId}
                                className={clsx(
                                    {
                                        'dark:bg-neutral-700/80':
                                            index % 2 === 0,
                                        'dark:bg-neutral-700/50':
                                            index % 2 !== 0,
                                        'dark:bg-theme-primary-700/80':
                                            node.nodeId === currentNodeId,
                                    },

                                    node.nodeId === currentNodeId
                                        ? 'dark:text-theme-primary-500'
                                        : '',
                                )}>
                                <td className="p-2 px-3 border-b border-neutral-600">
                                    <Text
                                        textStyle={
                                            node.nodeId === currentNodeId
                                                ? TextStyle.Primary
                                                : TextStyle.Default
                                        }>
                                        {node.title}
                                    </Text>
                                </td>
                                <td className="p-2 px-3 border-b border-neutral-600">
                                    <NodeStatusBadge state={node.state} />
                                </td>
                                <td className="p-2 px-3 border-b border-neutral-600">
                                    <Button
                                        density="compact"
                                        buttonText={
                                            node.nodeId === currentNodeId
                                                ? 'Return'
                                                : 'Open'
                                        }
                                        variant={
                                            node.nodeId === currentNodeId
                                                ? 'secondary'
                                                : 'tertiary'
                                        }
                                        onClick={() =>
                                            handleNavigateClick(node.nodeId)
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};

export default NodeTablePage;
