import { ConnectionLineComponentProps } from 'reactflow';
import { myTheme } from '@jameswilliamknight/starbridge-ui';

const getColour = (colour: string, value: number) => {
    const colours = myTheme.extend.colors;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const twColour = colours ? (colours as any)[colour][value] : undefined;
    return twColour;
};

const CustomConnectionLine = ({
    fromX,
    fromY,
    toX,
    toY,
}: ConnectionLineComponentProps) => {
    // Determine the middle point for the curve
    const middleX = (fromX + toX) / 2;
    const middleY = (fromY + toY) / 2;

    const isGraphHorizontal = true;

    const strokeColor = getColour('theme-primary', 400);
    // Create a Bezier path that depends on whether the layout is horizontal or vertical
    const pathD = isGraphHorizontal
        ? `M${fromX},${fromY} C ${middleX},${fromY} ${middleX},${toY} ${toX},${toY}`
        : `M${fromX},${fromY} C ${fromX},${middleY} ${toX},${middleY} ${toX},${toY}`;

    return (
        <g>
            <path
                fill="none"
                stroke={strokeColor}
                strokeWidth={1.5}
                className="animated"
                d={pathD}
            />
            <circle
                cx={toX}
                cy={toY}
                fill="#fff"
                r={3}
                stroke="#222"
                strokeWidth={1.5}
            />
        </g>
    );
};
export default CustomConnectionLine;
